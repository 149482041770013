import { Patient } from "./Patient";
import { Referral } from "./Referral";
import { PhoneNumber } from "./PhoneNumber";
import { InvoiceEntry } from "./InvoiceEntry";
import { EmailSent } from "@/models/EmailSent";
import { HealthcareProvider } from "./HealthcareProvider";
import { HealthcareProviderId } from "./HealthcareProviderId";
import { User } from "./User";
import { getUser } from "@/libraries/plugins/getUser";
import { PayerInsurer } from "./PayerInsurer";
import { BankAccount } from "./BankAccount";
import { Contact } from "./Contact";
import { InvoicePayment } from "./InvoicePayment";
import { Declaration } from "./Declaration";
import { hasFeatureFlag } from "@/libraries/plugins/hasFeatureFlag";
import moment from "moment";
import { InvoiceType } from "@/apis/patients/invoices";
import { InvoiceEntryWithData } from "@/libraries/repositories/collectionInvoiceEntriesWithDataRepository";
import { sortObjectByKey } from "@/libraries/utils/sortFunctions";

export const invoiceStatus = [
  "draft",
  "open",
  "paid",
  "void",
  "uncollectible",
  "collection",
  "collection_draft",
  "collection_void",
  "partial",
  "correction",
  "to_collect",
] as const;
export type InvoiceStatus = (typeof invoiceStatus)[number];

export const invoiceFilterStatus = [
  "draft",
  "open",
  "declared",
  "undeclared",
  "partial",
  "rejected",
  "paid",
  "granted",
  "partially_granted",
  "reminded",
  "expired",
  "uncollectible",
  "correction",
  "collection_draft",
  "collection_void",
  "void",
  "external_claim",
  "to_collect",
] as const;

export type InvoiceFilterStatus = (typeof invoiceFilterStatus)[number];

export type Invoice = {
  id: number;
  user_id: number;
  patient?: Patient;
  patient_zis_number?: number;
  patient_address_id?: number;
  pay_date?: Date | string;
  payer_insurer?: PayerInsurer;
  payer_insurer_id?: number;
  payer_contact?: Contact;
  payer_contact_id?: number;
  referral_id?: number;
  invoice_entries?: InvoiceEntry[];
  email_sents?: EmailSent[];
  number?: string;
  date?: Date | string;
  VAT?: number;
  price_ex_vat: number;
  price_inc_vat: number;
  prices_include_tax: boolean;
  healthcare_provider?: HealthcareProvider;
  referral?: Referral;
  patient_address?: any;
  hcp_address?: any;
  hcp_bank_account?: BankAccount;
  hcp_phone_number?: PhoneNumber;
  hcp_kvk?: HealthcareProviderId;
  hcp_agb?: HealthcareProviderId;
  hcp_address_id?: number;
  hcp_agb_id?: number;
  hcp_btw_id?: number;
  hcp_kvk_id: number;
  hcp_phone_number_id?: number;
  hcp_bank_account_id?: number;
  status: InvoiceStatus;
  filter_status: InvoiceFilterStatus;
  paid_in_advance: boolean;
  correcting_invoice_id?: number;
  credits_invoice_id?: number;
  total_paid: number;
  total_unpaid: number;
  collection_invoice_id?: number;
  company_id?: string;
  company_administration_id?: string;
  type: InvoiceType;
  readonly created_at?: Date;
  readonly updated_at?: Date;
};

export type InvoiceApi = Invoice & {
  user: User;
  invoice_entries: InvoiceEntry[];
  corrections: Array<
    Invoice & {
      email_sents: EmailSent[];
      declarations: Declaration[];
      invoice_entries: InvoiceEntry[];
    }
  >;
  collection_invoice_id?: number;
  collection_invoice_filter_status?: InvoiceFilterStatus;
  collection_invoice?: Invoice & { uzovi: string };
  validations?: Validation[];
  email_sents: EmailSent[];
  invoice_payments: InvoicePayment[];
  invoice_events: InvoiceEvent[];
  void_date?: string;
  finalised_date?: string;
  declarations: Declaration[];
  period_start?: string;
  period_end?: string;
  price_specification?: {
    taxes: {
      [key: string]: {
        taxed: number;
        tax: number;
      };
    };
    subtotal: number;
    total: number;
  };
};

export type InvoiceEvent = {
  uuid: string;
  invoice_id: number;
  user_id: number;
  type: string;
  created_at: string;
  updated_at: string;
};

export type CollectionInvoiceApi = InvoiceApi & {
  uzovi: string;
  partial_invoices: InvoiceApi[];
  credit_invoices?: Invoice[];
  period_start: string;
  period_end: string;
  is_processed?: boolean;
};

export type Validation = {
  id: number;
  status: "Afgekeurd" | "CorrectBevonden" | null;
  reference: string;
  response?: {
    Meldingen: {
      Melding: Pm304Error[] | Gds801Errors;
    };
  };
  created_at: string;
  updated_at: string;
};

export type Gds801Errors = {
  Header: ValidationElement[];
  Overzicht: ValidationElement[];
  Verzekerde: ValidationElement[];
};

export type Pm304Error = {
  Code: string;
  Regelnummer?: string;
  Omschrijving: string;
};

export type ValidationElement = {
  betrokkenElementen: {
    elementnaam: string;
    elementwaarde: string;
  }[];
};

export type InvoicePatientApi = Invoice & {
  user: User;
  invoice_entries: InvoiceEntry[];
  corrections: Array<
    Invoice & {
      email_sents: EmailSent[];
      declarations: Declaration[];
      invoice_entries: InvoiceEntry[];
    }
  >;
};
export interface StripeInvoice {
  id: string;
  object: "invoice";
  amount_due: number;
  amount_paid: number;
  amount_remaining: number;
  date: number;
  hosted_invoice_url: string;
}

export function getLatestCorrection<
  TInvoice extends { corrections: { id: number; email_sents: unknown[] }[] },
>(invoice: TInvoice) {
  return invoice.corrections.toSorted((a, b) => b.id - a.id)[0];
}

export function getLatestInvoice<
  OriginalInvoice extends { id: number },
  CorrectionInvoice extends { id: number },
>(
  invoice: OriginalInvoice & { corrections: CorrectionInvoice[] },
): OriginalInvoice | CorrectionInvoice {
  const sortedCorrections = invoice.corrections.toSorted((a, b) => b.id - a.id);
  return sortedCorrections[0] ?? invoice;
}

export function getLatestDeclaration<T>(
  invoice: T & { declarations: Declaration[] },
) {
  return invoice.declarations.toSorted((a, b) => b.id - a.id)[0];
}

export function getInvoiceCreateUrl(type: InvoiceType, zisNumber?: number) {
  if (type === "company.product") {
    return `/finance/company_product/create`;
  }
  if (!zisNumber) {
    throw new Error("Expected zis number for patient invoice create url");
  }
  switch (type) {
    case "patient.pm304":
      return `/patient/${zisNumber}/invoices/create`;
    case "patient.product":
      return `/patient/${zisNumber}/product_invoices/create`;
    default:
      throw new Error("Unexpected invoice type");
  }
}

export function getEditUrl(
  invoice: Pick<Invoice, "id" | "patient_zis_number" | "type">,
): string {
  switch (invoice.type) {
    case "patient.pm304":
    case "insurer.partial.pm304":
      return `/patient/${invoice.patient_zis_number}/invoices/${invoice.id}/edit`;
    case "patient.product":
      return `/patient/${invoice.patient_zis_number}/product_invoices/${invoice.id}/edit`;
    case "insurer.partial.gds801":
      return `/patient/${invoice.patient_zis_number}/invoices/${invoice.id}/edit`;
    case "company.product":
      return `/invoices/${invoice.id}/edit`;
    case "insurer.collection.pm304":
      throw new Error("Collection does not have edit url");
    case "insurer.collection.gds801":
      throw new Error("Collection does not have edit url");
    default:
      throw new Error("Unexpected invoice type");
  }
}

export function getDetailsUrl(
  invoice: Pick<
    Invoice,
    "collection_invoice_id" | "status" | "id" | "patient_zis_number" | "type"
  >,
): string {
  if (invoice.status === "draft") {
    throw new Error("Draft invoice does not have details");
  }

  switch (invoice.type) {
    case "insurer.partial.pm304":
      return `/invoices/${invoice.collection_invoice_id}`;
    case "insurer.collection.pm304":
      return `/invoices/${invoice.id}`;
    case "insurer.partial.gds801":
      return `/invoices/${invoice.collection_invoice_id}`;
    case "insurer.collection.gds801":
      return `/invoices/${invoice.id}`;
    case "patient.pm304":
    case "patient.product":
      return `/patient/${invoice.patient_zis_number}/invoices/${invoice.id}`;
    case "company.product":
      return `/invoices/${invoice.id}`;
    default:
      throw new Error("Unexpected invoice type: " + invoice.type);
  }
}

export const getRecentValidation = (
  validations: Validation[],
  partialsOrEntries: (Invoice | InvoiceEntry | InvoiceEntryWithData)[],
): Validation | undefined => {
  const mostRecentPartialInvoice = partialsOrEntries.reduce(
    (acc, invoice) => Math.max(acc, moment(invoice.updated_at!).unix()),
    0,
  );

  const sortedValidations = validations.toSorted((a, b) =>
    sortObjectByKey("desc", "created_at", a, b),
  );

  return sortedValidations.find((val): boolean => {
    return moment(val.created_at).isAfter(
      moment(mostRecentPartialInvoice * 1000),
    );
  });
};

export const getDefaultStatuses = (): InvoiceFilterStatus[] => {
  if (!hasFeatureFlag("vecozo-declaration", getUser())) {
    return ["draft", "open", "paid", "reminded", "expired", "void"];
  }

  const result: InvoiceFilterStatus[] = [
    "draft",
    "open",
    "paid",
    "reminded",
    "expired",
    "void",
    "declared",
    "rejected",
    "undeclared",
    "partially_granted",
    "granted",
    "collection_draft",
  ];

  if (hasFeatureFlag("ggn")) {
    result.push("external_claim" as const);
  }

  return result;
};

export function canDeleteInvoice(invoice: InvoiceApi) {
  if (invoice.status === "draft" || invoice.status === "collection_draft") {
    return true;
  }

  if (invoice.status === "partial") {
    const collectionInvoice = invoice.collection_invoice;
    if (!collectionInvoice) {
      throw new Error(
        "Cannot decide if partial invoice can be deleted without collection invoice",
      );
    }
    return collectionInvoice.status === "collection_draft";
  }

  return false;
}
